import { useState, useEffect } from "react";
import Nav from "./nav";
import Footer from "./Footer";
const Photo = () => {

    const [img,setImg]= useState("");

    useEffect(()=>{
        let qurl = window.location.href;
        let query= qurl.split('?');
        let url="";
        if(query.length > 1)
        
         url   = "https://schoolapi.jyotipedia.com/api/gallary?"+query[1];
        
        else
            url = "https://schoolapi.jyotipedia.com/api/gallary";
        
       
        callData(url);
    },[]);

   
    const callData=async(url)=>{
        console.log(url);
        const res= await fetch(url);
        const data= await res.json();
        console.log(data.gallary_data);
        setImg (data.gallary_data);
    }

    const getImg = (url) => {
        const parts = url.split("\/");
        const newurl = "https://schoolapi.jyotipedia.com/image/" + parts[1];
       // console.log(newurl);
        return newurl;
    }

    return (
        <>
            <Nav></Nav>
            <div className="news_section layout_padding" style={{ "marginBottom": "2em" }}>
                <div className="container">
                    <h1>Photo gallery</h1>
                    <hr></hr>
                    <div className="row">
                  
                        {img.length > 0 && img.map((image)=>
                        <>
                       
                        <div className="col-lg-4 col-md-4 picGel">
                            <img src={getImg(image.img)}></img>
                         </div>
                        </>                        
                        )}
                    

                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
}

export default Photo;