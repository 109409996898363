import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

const WhatweDo = () => {

    const [data,setData]=useState("");  
    let navigate= useNavigate();  

    useEffect(()=>{
        const url = "https://schoolapi.jyotipedia.com/api/content?nameType=Facelities";
        GetData(url);
    },[]);

    const GetData= async(url)=>{
        const data = await fetch(url);
        const res = await data.json();     
        setData(res.content_data);
    } 

    const getImg=(url)=>{       
        const parts = url.split("\/");
        const newurl= "https://schoolapi.jyotipedia.com/image/"+parts[1];       
        return newurl;
    }

    const routeChange=(id)=>{
    let path="Home/"+id;
    navigate(path);
  }

    


    return (

        <>
       
        <div className="news_section layout_padding " style={{"backgroundColor": "rgb(231 253 77 / 86%)"}}>
            <div className="container">

            <div className="row d-flex align-items-center justify-content-center vh-100">
				<div className="col-md-8" style={{ "textAlign": "center"}}>
                <h1 className="news_taital">Our Speciality</h1>
					{/* <p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p> */}
				</div>
			</div>

                <div className="row">
                {data.length > 0 && data.map((datas)=> <>
                    
                    <div className="card cardman col-lg-4 col-md-4">
                       <img className="card-img-top" src={getImg(datas.img)} alt="Card image cap" /> 
                        <div className="card-body">
                            <h3 className="card-title">{datas.heading}</h3>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: datas.textData }}></p> 
                            <button className="buttoncust" onClick={()=>routeChange(datas._id)}>Read More</button>
                                                 
                        </div>
                    </div>
                </>)}
                </div>
            </div>
        </div>
        <div className="news_section layout_padding" >
            <div className="container">
                <div className="row">
                    <div className=" col-lg-3 col-md-3 gutter padding" style={{"backgroundColor": "teal", "text-align": "-webkit-center",  "border-right": "solid 2px white", "padding": "32px"}}><i className="fa fa-user" style={{"font-size": "-webkit-xxx-large","color": "white"}}></i><h2 style={{"color": "white"}}>Student</h2><h3 style={{"color": "white"}}>2236</h3></div> 
                    <div className="col-lg-3 col-md-3 gutter padding" style={{"backgroundColor": "teal", "text-align": "-webkit-center",  "border-right": "solid 2px white", "padding": "32px"}}><i className="fa fa-users"  style={{"font-size": "-webkit-xxx-large","color": "white"}}></i><h2 style={{"color": "white"}}>Teachers</h2><h3 style={{"color": "white"}}>90</h3></div>
                    <div className="col-lg-3 col-md-3 gutter padding" style={{"backgroundColor": "teal", "text-align": "-webkit-center",  "border-right": "solid 2px white", "padding": "32px"}}><i className="fa fa-trophy" style={{"font-size": "-webkit-xxx-large","color": "white"}}></i><h2 style={{"color": "white"}}>Winning Awards</h2><h3 style={{"color": "white"}}>1250</h3></div>
                    <div className="col-lg-3 col-md-3 gutter padding" style={{"backgroundColor": "teal", "text-align": "-webkit-center",  "border-right": "solid 2px white", "padding": "32px"}}><i className="fa fa-users" style={{"font-size": "-webkit-xxx-large","color": "white"}}></i><h2 style={{"color": "white"}}>Members</h2><h3 style={{"color": "white"}}>200</h3></div>
                </div>
                </div>
                </div>

        </>         
    );
}
export default WhatweDo;