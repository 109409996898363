import React from "react";

import { useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router";

const Enquiry = () => {


    // const [content, setContent] = useState();
    const [textboxValue, setTextboxValue] = useState({   
       name : "",
       ph: "",
       email:"",
       enqtext:""
    });
  
    

    const handleTextboxChange = (event) => {
        const { name, value } = event.target

        setTextboxValue({
            ...textboxValue,
            [name]: value
        });
    };

   

    
    const handelSubmit = (e) => {
        e.preventDefault();
        const url = "https://schoolapi.jyotipedia.com/api/enquiry/add";
        if(textboxValue.ph && textboxValue.name && textboxValue.email && textboxValue.enqtext){
            SubmitData(url);
        }
        else{
            toast.error("Please Fill Complete Information" , {
                position: "top-right",
                theme: "colored",
            });
        }

       
    }

    const SubmitData = async (url) => {

        const formData = {
            name : textboxValue.name,
            ph: textboxValue.ph,
            email:textboxValue.email,
            enqText:textboxValue.enqtext
        }

        try {

            const res = await fetch(url, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(formData)
            });

            const res_data = res.status;
            if (res_data == "200") {
                toast.success("Enquiry Uploded Successfull", {
                    position: "top-right",
                    theme: "colored",
                });
            }
            else {
                toast.error("Enquiry Process Failed from Backend", {
                    position: "top-right",
                    theme: "colored",
                });
            }
        }
        catch (error) {
            toast.error("Enquiry Process Failed from Frontend" + error, {
                position: "top-right",
                theme: "colored",
            });
        }
    }

    return (
        <div className="contact_section layout_padding">
        <div className="container">
           <div className="row">
              <div className="col-sm-12">
                 <h1 className="contact_taital">Enquiry Form</h1>
              </div>
           </div>
           <div className="contact_section_2">
              <div className="row">
                 <div className="col-md-12">
                    <div className="mail_section map_form_container">
                       <form action="">
                       <input type="text" name="name" className="mail_text" onChange={handleTextboxChange} value={textboxValue.name} placeholder="Name" />
                       <input type="text" name="ph" className="mail_text"onChange={handleTextboxChange} value={textboxValue.ph} placeholder="Phone Number" /> 
                       <input type="text" name="email" className="mail_text"onChange={handleTextboxChange} value={textboxValue.email} placeholder="Your Email"/>
                       <textarea name="enqtext" className="massage-bt" placeholder="Message" onChange={handleTextboxChange} value={textboxValue.enqtext} rows="5" id="comment" ></textarea>
                       <button type="submit" class="btn btn-primary" onClick={handelSubmit}>Submit</button>
                       </form>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <ToastContainer />
     </div>
    );


}

export default Enquiry;