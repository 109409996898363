
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const Notice = () => {

    const [Heading, setHeading] = useState("");
   
    useEffect(() => {
        const url = "https://schoolapi.jyotipedia.com/api/content?pageName=Notices";
        GetData(url);
    }, [])

    const GetData = async (url) => {
        const res = await fetch(url);
        const data = await res.json();
       // console.log(data.content_data);
        setHeading(data.content_data);
    }

    return (
        <>
       
        <div className="news_section ">
            <div className="row">
                <div className="col-lg-12"  >
                <marquee direction="left" >
                {Heading.length >0 && Heading.map((link,i)=>
                    <>
                   <Link to={{ pathname: `../Notices/${link._id}` }}><h4 style={{"display": "inline", "marginLeft":"15px"}} className="appoinment_text">{link.heading}</h4></Link>
                    {/* <a href={`../Notices/${link._id}`}  style={{"padding":"6px","font-size": "large"}}>{link.heading}</a> */}
                    </>
                    )}

                </marquee>
                   
                    
                    
                </div>
                </div>
            </div>
            </>
    );
}

export default Notice;