import React from "react";
import { useState,useEffect } from "react";

const Misson = () => {

    const [data,setData]=useState("");    

    useEffect(()=>{
        const url = "https://schoolapi.jyotipedia.com/api/content?nameType=Mission for Achievement";
        GetData(url);
    },[]);

    const GetData= async(url)=>{
        const data = await fetch(url);
        const res = await data.json();   
        //console.log(res.content_data);  
        setData(res.content_data[0].textData);
    } 

    
    return (


        <div className="about_section layout_padding margin_top90" style={{ "backgroundColor": "rgb(58 249 67 / 80%)" }}>

            <div className="container">
                <div className="row d-flex align-items-center justify-content-center vh-100">
                    <div className="col-md-8" style={{ "textAlign": "center" }}>
                    <h1 className="news_taital">Mission Of Achivement</h1>
                       {/*  <p>Dignissimos asperiores vitae velit veniam totam fuga molestias accusamus alias autem provident. Odit ab aliquam dolor eius.</p> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <p dangerouslySetInnerHTML={{ __html: data }}></p>
                    </div>
                    <div className="col-lg-6 col-md-6 cont">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/hbfnmtfXJzo" frameborder="0" allowfullscreen="allowfullscreen" className="video"></iframe>
                    </div>
                </div>
            </div>
        </div>


        /* </div> */
    );
}
export default Misson;