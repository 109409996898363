import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";

const WhatweDo2 = () => {

    const [data,setData]=useState("");   
    let navigate= useNavigate();   

    useEffect(()=>{
        const url = "https://schoolapi.jyotipedia.com/api/content?nameType=What We Do";
        GetData(url);
    },[]);

    const GetData= async(url)=>{
        const data = await fetch(url);
        const res = await data.json();     
        setData(res.content_data);
    } 

    const getImg=(url)=>{       
        const parts = url.split("\/");
        const newurl= "https://schoolapi.jyotipedia.com/image/"+parts[1];
       // console.log(newurl);
        return newurl;
    }
    const routeChange=(id)=>{
        let path="What we do/"+id;
        navigate(path);
      }


    return (
        <div className="news_section layout_padding" style={{"backgroundColor": "rgb(245 40 169 / 79%)"}}>
            <div className="container">

            <div className="row d-flex align-items-center justify-content-center vh-100">
				<div className="col-md-8" style={{ "textAlign": "center"}}>
                <h1 className="news_taital">What We DO</h1>
					
				</div>
			</div>

                <div className="row">
                {data.length > 0 && data.map((datas,i)=> <>
                   {i < 3 && <>
                    <div className="card cardman gutter  col-lg-4 col-md-4" style={{"backgroundColor": "antiquewhite"}}>
                      {/*  <img className="card-img-top" src={getImg(datas.img)} alt="Card image cap" />  */}
                        <div className="card-body">
                            <h2 className="card-title">{datas.heading}</h2>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: datas.textData }}></p>                            
                            <button className="buttoncust" onClick={()=>routeChange(datas._id)}>Read More</button>
                        </div>
                    </div>
               </>} </>)}
                
                </div>
            </div>
        </div>


    );
}
export default WhatweDo2;